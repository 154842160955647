import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import http from './http/request'
import env from './http/env.js'
import util from './utils/util'

import './assets/style/common.less'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$http = http
Vue.prototype.$env = env
Vue.prototype.$util = util

// NProgress.configure({
//   easing: 'ease',
//   speed: 500,
//   showSpinner: false,
//   trickleSpeed: 200,
//   minimum: 0.3
// })
// 路由监听
router.beforeEach((to, from, next) => {
  // NProgress.start()
  next()
})


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const env = process.env.NODE_ENV
const IP = {
  development: { // 本地开发环境
    baseIP: 'https://admin.youyifeifan.cn/fzxm'
  },
  production: { // 生产环境
    baseIP: 'https://admin.youyifeifan.cn/fzxm'
  },
  test: { // 测试环境
    baseIP: 'https://admin.youyifeifan.cn/fzxm'
  }
}
export default IP[env]

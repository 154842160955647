<template>
  <div id="app">
    <div class="main">
      <!-- <transition name="slide-fade"> -->
        <keep-alive>
          <router-view v-if="isRouterAlive" :key='$route.fullPath'></router-view>
        </keep-alive>
      <!-- </transition> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './components/footer.vue'


export default {
  name: 'App',
  components: {
    Footer
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  watch: {
    $route:{
      handler(){
		// 初始化操作
      },
      immediate: true,
      deep: true,
    }
  },

  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  font: 400 1em / 1.8 PingFang SC, Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slide-fade {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
}
.slide-fade-enter,
.slide-fade-leave-to {
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
  transform: translateY(100%);
}
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
  transform: translateY(-100%);
  z-index: 100;
}
.special_search .el-input__inner:focus {
  border-width: 3px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    {
      path: '*',
      name: '404',
      component: () => import('../views/error/errorPage.vue')
    },
    { path: '/', redirect: '/index' },
    { path: '/index', component: () => import('../views/home/homeIndex.vue') },
    { path: '/detail', component: () => import('../views/home/detailPage.vue') },
    { path: '/list', component: () => import('../views/home/listPage.vue') },
    { path: '/sub', component: () => import('../views/home/subPage.vue') },
    { path: '/zt', component: () => import('../views/home/zhuantiPage.vue') },
  ]
})

// 挂载导航路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title
  } else {
    document.title = '法制新媒'
  }
  // const whiteList = [
  //   '/login',
  //   '/pad/welcome', // 大屏欢迎页
  //   '/pad/screen', // 大屏倒计时
  //   '/pad/answer', // pad答题
  //   '/pad/done', // pad提交
  //   '/pad/rank', // 大屏排名
  //   '/pad/login', // pad登录
  //   '/pad/pw', // pad欢迎页
  //   '/stuLogin',
  //   '/padSemiBefore',
  //   '/padSemiQuiz',
  //   'padSemiResult'
  // ]
  // if (whiteList.includes(to.path)) return next()
  // if (!localStorage.getItem('Authorization')) return next('/login')
  next()
})

export default router
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
<template>
  <div>
    <div class="footer">
      <div class="common-width flex-between">
        <div class="lf flex-around">
          <div class="ul" v-for="(arr, i) in linkFriends" :key="i">
            <div class="li" v-for="(item, j) in arr" :key="j">
              <a :href="item.linkUrl" target="_blank">
                {{item.linkName}}
              </a>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="md">
          <div class="flex-around">
            <div class="info">
              <div>主办单位：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
              <div>承办单位：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
              <div>协办单位：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
              <div>地址：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
              <div>电话：xxxxxxxxxxx</div>
              <div>版权</div>
              <div>备案号</div>
            </div>
            <div class="info">
              <div>网上有害信息举报</div>
              <div>中国互联网联合辟谣平台</div>
              <div>电子邮箱：xxx@163.com</div>
            </div>
          </div>
          <div></div>
        </div>
        <div class="line"></div>
        <div class="rt">
          <div class="rt-item">
            <div class="flex">
              <img src="../assets/image/icon_gouwu.png" alt="">
                央广购物
            </div>
          </div>
          <div class="rt-item">
            <div class="flex">
              <img src="../assets/image/icon_gouwu.png" alt="">
                央广购物
            </div>
          </div>
          <div class="rt-item">
            <div class="flex">
              <img src="../assets/image/icon_gouwu.png" alt="">
                央广购物
            </div>
          </div>
          <div class="rt-item">
            <div class="flex">
              <img src="../assets/image/icon_gouwu.png" alt="">
                央广购物
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qr" v-if="info.images">
      <!-- <div class="qr-item" v-for="(item, i) in 11" :key="i">
        <div>法治新媒</div>
        <div>微信号</div>
        <img src="../assets/image/1.png" alt="">
      </div> -->
      <a :href="info.url" target="_blank">
        <img :src="info.images" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerPage',
  props: {
    msg: String,
  },
  data () {
    return {
      linkFriends: [],
      info: {}
    }
  },
  mounted () {
    this.getFriends()
    this.getAd()
  },
  methods: {
    getFriends() {
      this.$http.get('/fzxm/link/list?pageNum=1&pageSize=100').then(res => {
        if (res.code === 200) {
          let arr = res.rows.filter(item => item.linkType === '1')
          for(var i=0,len=arr.length;i<len;i+=6){
            this.linkFriends.push(arr.slice(i,i+6));
          }
        }
      })
    },
    getAd() {
      this.$http.get('/fzxm/ad/list?pageNum=1&pageSize=1&typeId=5').then(res => {
      if (res.code === 200) {
        this.info = res.rows[0]
        if (this.info) {
          this.info.images = this.$env.baseIP + this.info.images
        }
      }
      })
    },
  }
}
</script>

<style scoped lang="less">
.footer{
  padding: 64px 0 38px;
  width: 100%;
  background-color: #333;
  color: #f1f1f1;
}
.line{
  width: 1px;
  height: 180px;
  background-color: #272727;
}
.lf{
  flex: 1;
  align-items: flex-start;
}
.md{
  width: 480px;
}
.rt{
  flex: 1;
  margin-left: 40px;
}
.info{
  font-size: 12px;
  text-align: left;
}
.rt-item{
  width: 50%;
  display: inline-block;
  margin-bottom: 20px;
  img{
    margin-right: 10px;
  }
}
.qr{
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  img{
    width: 100%;
    height: auto;
  }
}
.li{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
</style>

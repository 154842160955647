import axios from 'axios'// 引入axios
import env from '../http/env'
// import router from '../router'
import Vue from 'vue'
import { Promise } from 'core-js'
import qs from 'qs'
// application/json;charset=UTF-8;
// 创建axios实例
const service = axios.create({
  // 这里拿线上接口测试
  baseURL: env.baseIP,
  headers: { // 请求头
    'Content-Type': 'application/json;charset=UTF-8;'
  },
  settimeout: 6000 // 超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(config)
    // Vue.$showLoading.show()
    if (localStorage.getItem('Authorization')) {
      config.headers.Authorization = localStorage.getItem('Authorization')
    }
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config // 记得一定要 返回config
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use((res) => {
  if (res.status && res.status === 200) {
    if (res.headers && res.headers.authorization) {
      const authorization = res.headers.authorization
      localStorage.setItem('Authorization', authorization)
    }
    if (res.data.code === 200) {
      return res.data
    } else if (res.data.code === 401) {
      //
    } else {
      Vue.prototype.$message.error(res.data.msg)
    }
    return Promise.reject(res)
  }
}, err => {
  Vue.$showLoading.hide()
  
  if (err.status === 401) {
    // Vue.prototype.$message.error(titleStr6)
    // router.push({
    //   path: '/chnEngLogin',
    //   params: {
    //     pushUrl: ''
    //   }
    // })
  } else {
    Vue.prototype.$message.error(JSON.stringify(err))
  }
  return Promise.reject(err)
})
export default service
